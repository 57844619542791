import { getConfig } from "@di";
import { generateMockData } from "@services/mocks/mockUtils";
import { DetectionPartialDTO, DetectionDetailDTO, BBox } from "./types";
import { DetectionDetailed, DetectionPartial } from ".";

export const getDetectionPartialMock = (
  v: Partial<DetectionPartial> = {}
): DetectionPartial => {
  const mock = new DetectionPartial(null, "api", getConfig());
  mock.id = "1";
  mock.type = "type";
  mock.label = { "en-GB": "label" };
  mock.start = 1000;
  mock.startType = "startType";
  mock.startLabel = { "en-GB": "startLabel" };
  mock.end = 2000;
  mock.endType = "endType";
  mock.endLabel = { "en-GB": "endLabel" };
  mock.endState = "endState";
  Object.assign(mock, v);

  return mock;
};

export const getDetectionDetailedMock = (
  v: Partial<DetectionDetailed | DetectionPartial> = {}
): DetectionDetailed => {
  const mock = new DetectionDetailed(null, "api", getConfig(), false);

  mock.id = "1";
  mock.type = "type";
  mock.label = { "en-GB": "label" };
  mock.start = 1000;
  mock.startType = "startType";
  mock.startLabel = { "en-GB": "startLabel" };
  mock.end = 2000;
  mock.endType = "endType";
  mock.endLabel = { "en-GB": "endLabel" };
  mock.endState = "endState";
  mock.startState = "startState";
  mock.startConfidence = 1;
  mock.startDetectionGap = null;
  mock.endConfidence = 1;
  mock.endDetectionGap = null;
  mock.bboxRanges = {};

  Object.assign(mock, v);

  return mock;
};

export const getDetectionShortDTOMock = generateMockData<DetectionPartialDTO>({
  id: "63867c8232e6ca480a757591",
  confidence: 1,
  start_ts: 162425000,
  end_ts: 162425600,
  start_type: "start_type",
  end_type: "end_type",
  op_name: "op_name",
});

export const getDetectionFullDTOMock = generateMockData<DetectionDetailDTO>({
  id: "63867c8232e6ca480a757591",
  confidence: 1,
  start_ts: 162425000,
  end_ts: 162425600,
  start_type: "start_type",
  end_type: "end_type",
  op_name: "op_name",
  start_confidence: 1,
  end_confidence: 1,
  start_detection_gap: 1,
  end_detection_gap: 1,
  start_state: "start_state",
  end_state: "end_state",
  bbox_ranges: {},
});

export const getBboxMock = (v: Partial<BBox> = {}): BBox => ({
  id: "bbox",
  box: { x1: 0, y1: 0, x2: 1, y2: 1 },
  startTs: 1000,
  endTs: 2000,
  hires: [],
  ...v,
});
