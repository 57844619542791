import { makeAutoObservable } from "mobx";

import { captureException } from "@sentry/browser";
import { TURNAROUND_MIN_DURATION, IS_DEV } from "@constants/common";
import { di } from "@di";
import { Entity } from "@models/entity";
import { OutboundFlight, InboundFlight } from "@models/flightInfo";
import { TurnaroundParams } from "@entities/turnaround/types";
import { convertObjUtcToMilliseconds, camelCaseKeys } from "@services/utils";
import { getNow, toMilliSeconds, toMilliSecondsOrNull } from "@services/time";
import { AirportConfigStore } from "@stores/AirportConfigStore";
import { DetectionPartial } from "@entities/detection";
import {
  parseTurnParams,
  prepareTurnaroundParams,
  generateExtendedTurnaroundParams,
  createTurnaroundParams,
  getProgressColor,
  getCountdownValue,
} from "./helpres";
import { TTurnaroundDTO, TurnProgressColor, Replay, TTurnState } from "./types";
export class Turnaround implements Entity {
  id: string = "";
  start: number = 0;
  end: number | null = null;
  authorized: boolean = false;
  replays: Record<string, Replay> = {};
  inboundFlight: InboundFlight | null = null;
  outboundFlight: OutboundFlight | null = null;
  progress: number | null = null;
  standId: string = "";
  originalStandId?: string;
  dedicatedAirline: string | null = null;
  params: TurnaroundParams = createTurnaroundParams();
  /**
   * "aircraft" - an aircraft is on the stand
   */
  state: TTurnState | null = null;
  aircraftType: string | null = null;

  parametersAreExtended: boolean = false;

  constructor(
    dto: TTurnaroundDTO,
    private _airportConfigStore: AirportConfigStore
  ) {
    this.update(dto);

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isHistoricTurn(): boolean {
    return Boolean(this.end);
  }

  get progressColor(): TurnProgressColor {
    // if (!this.parametersAreExtended) {
    //   throw new Error("Apply extendParametersFromDetections first");
    // }

    return getProgressColor(
      this.params,
      this.isHistoricTurn,
      this._airportConfigStore
    );
  }

  get countdownValue(): number | null {
    // if (!this.parametersAreExtended) {
    //   throw new Error("Apply extendParametersFromDetections first");
    // }

    return getCountdownValue(this.params, this._airportConfigStore);
  }

  get icao() {
    return (
      this.outboundFlight?.companyIata ||
      this.inboundFlight?.companyIata ||
      this.dedicatedAirline ||
      null
    );
  }

  extendParametersFromDetections(detections: DetectionPartial[]) {
    const { turnParamsSource } = this._airportConfigStore;
    this.params = generateExtendedTurnaroundParams(
      this.params,
      turnParamsSource,
      detections
    );
    this.parametersAreExtended = true;
  }

  update(dto: TTurnaroundDTO) {
    const { turnParamsSource, fixPOBTOutOfBounds } = this._airportConfigStore;

    if ("replays" in dto) {
      // Missing type for dto.replays
      const replays = Object.entries(dto.replays).reduce<
        Record<string, Replay>
      >((acc, [key, rawReplay]) => {
        acc[key] = {
          url: rawReplay.url,
          speed: rawReplay.speed,
          startTs: toMilliSeconds(rawReplay.start_ts || 0),
          endTs: toMilliSeconds(rawReplay.end_ts || dto.end_ts || 0),
        };
        return acc;
      }, {});
      this.replays = replays;
    }

    dto.params = dto.params || {};
    dto.params = prepareTurnaroundParams(dto.params, turnParamsSource);
    this.params = {
      ...parseTurnParams(dto.params),
      aobt: null,
      aibt: null,
      cobt: null,
    };

    this.id = dto.id;
    this.start = toMilliSeconds(dto.start_ts);
    this.end = toMilliSecondsOrNull(dto.end_ts);
    this.authorized = dto.authorized !== false;
    this.inboundFlight = dto.inbound_flight
      ? camelCaseKeys(dto.inbound_flight)
      : null;
    (this.outboundFlight = dto.outbound_flight
      ? camelCaseKeys(dto.outbound_flight)
      : null),
      (this.progress = dto.progress || null);
    this.standId = dto.stand_id;
    if ("original_stand_id" in dto) {
      this.originalStandId = dto.original_stand_id;
    }
    this.dedicatedAirline = dto.dedicated_airline || null;
    // TODO verify that state has valid value
    this.state = dto.state as TTurnState | null;
    this.aircraftType = null;

    //tmp hack for empty flight info
    if (this.inboundFlight && !Object.keys(this.inboundFlight).length) {
      this.inboundFlight = null;
    }
    if (this.outboundFlight && !Object.keys(this.outboundFlight).length) {
      this.outboundFlight = null;
    }

    if (this.inboundFlight) {
      this.inboundFlight = convertObjUtcToMilliseconds(this.inboundFlight);
      //TODO remove hack, aibt not included in params from api
      this.params.aibt = this.inboundFlight.actualInBlockTime || null;
    }
    if (this.outboundFlight) {
      this.outboundFlight = convertObjUtcToMilliseconds(this.outboundFlight);

      // cast to boolean in case of `isStarFlight` (`data.outbound_flight.is_star_flight`) is unexpected nullish value
      this.outboundFlight.isStarFlight = Boolean(
        this.outboundFlight.isStarFlight
      );

      //TODO remove hack, aobt not included in params from api
      this.params.aobt = this.outboundFlight.actualOffBlockTime || null;

      //TODO remove hack, cobt not included in params from api
      this.params.cobt = toMilliSecondsOrNull(
        dto.outbound_flight?.calculated_off_block_utc
      );
    }

    //TODO remove hack, eldt not included in params from api
    this.params.eldt = toMilliSecondsOrNull(
      dto.inbound_flight?.estimated_landing_time ||
        dto.outbound_flight?.estimated_landing_time
    );

    this.aircraftType =
      this.inboundFlight?.aircraftType ||
      this.outboundFlight?.aircraftType ||
      null;

    if (this.end) {
      this.progress = 1;
    }

    if (fixPOBTOutOfBounds) {
      if (this.end) {
        if (this.params.pobt !== null && this.params.aobt) {
          this.params.pobt = this.params.aobt;
        }
      } else if (this.params.pobt && this.params.pobt < getNow()) {
        this.params.pobt = getNow();
      }
    }

    if (this.end && this.end - this.start <= TURNAROUND_MIN_DURATION) {
      const error = new Error(
        `[API#parseTurnaround] Got invalid turnaround "${this.id}": duration is less than ${TURNAROUND_MIN_DURATION}.`
      );

      captureException(error);

      if (IS_DEV || di.resolve("configOverrides").isDebug) {
        console.error(error);
      }

      return null;
    }

    this.parametersAreExtended = false;
  }
}
