import { MutableRefObject } from "react";
import classNames from "classnames";

export const cn = classNames;
export const mergeRefs = <T extends Element = HTMLDivElement>(
  ...refs: (
    | ((instance: T | null) => void)
    | MutableRefObject<T | null>
    | null
    | undefined
  )[]
) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) {
    return null;
  }
  if (filteredRefs.length === 0) {
    return filteredRefs[0];
  }
  return (inst: T) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        // @ts-ignore
        ref.current = inst;
      }
    }
  };
};
