import { TurnaroundParameterKey } from "@frontend/configuration";
import { components as ApiSpec } from "@api/apiSpec";
import { TURN_STATE } from "@constants/strings";
import { KeysOf, ValuesOf } from "@services/type-utils";

export type TTurnState = ValuesOf<typeof TURN_STATE>;

export type TTurnaroundDTO =
  | ApiSpec["schemas"]["DetailedTurnaround"]
  | ApiSpec["schemas"]["TimelineTurnaround"]
  | ApiSpec["schemas"]["backend__v2__stands__schemas__Turnaround"];

export type TurnProgressColor = "grey" | "red" | "green";

export type Replay = {
  endTs: number;
  speed: number;
  startTs: number;
  url: string;
};

// TODO i dont like union, intersection seems better
export type TurnaroundParamName =
  | NonNullable<
      KeysOf<
        ApiSpec["schemas"]["backend__v2__stands__schemas__TurnaroundParams"]
      >
    >
  | TurnaroundParameterKey;

export type TurnaroundParams = Record<TurnaroundParamName, number | null> & {
  [PARAMS_NULLED_SYMBOL]?: TurnaroundParamName[];
};
export const PARAMS_NULLED_SYMBOL = Symbol(
  "Some turnaround params was nulled on client side"
);
