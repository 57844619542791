import { TAirportConfig } from "@frontend/configuration";
import { cloneDeep, groupBy } from "lodash";
import { DetectionPartial } from "@entities/detection";
import {
  PARAMS_NULLED_SYMBOL,
  TurnaroundParams,
} from "@entities/turnaround/types";
import { typedEntries } from "@services/utils";
import { dropSeconds, getNow, toMilliSecondsOrNull } from "@services/time";
import { TurnProgressColor } from "./types";

export const createTurnaroundParams = (
  payload: Partial<TurnaroundParams> = {}
): TurnaroundParams => ({
  aircraft_start_ts: null,
  aircraft_end_ts: null,
  aircraft_on_stand_start_ts: null,
  aircraft_on_stand_end_ts: null,
  aircraft_or_pushback_ts: null,
  aircraft_ready_ts: null,
  predicted_aircraft_ready_ts: null,
  flight_ardt: null,
  initial_eobt: null,
  aldt: null,
  eibt: null,
  eobt: null,
  ltd: null,
  pobt: null,
  sibt: null,
  sobt: null,
  tobt: null,
  aobt: null,
  aibt: null,
  tsat: null,
  asat: null,
  asrt: null,
  apbg: null,
  ctot: null,
  cobt: null,
  extra: null,
  eldt: null,
  ...payload,
});

export const prepareTurnaroundParams = (
  targetParams: any,
  turnParamsSource: TAirportConfig["turnParamsSource"]
) => {
  if (!targetParams) {
    targetParams = {};
  }

  const result = cloneDeep(targetParams);

  const turnParamsSourceEntries = typedEntries(turnParamsSource);
  if (turnParamsSourceEntries.length) {
    turnParamsSourceEntries.forEach(([flightStatusKey]) => {
      result[flightStatusKey] = null;
    });
    result[PARAMS_NULLED_SYMBOL] = turnParamsSourceEntries.map((v) => v[0]);
  }

  return result;
};

export const parseTurnParams = (params: any): TurnaroundParams => ({
  aircraft_end_ts: toMilliSecondsOrNull(params.aircraft_end_ts),
  aircraft_on_stand_end_ts: toMilliSecondsOrNull(
    params.aircraft_on_stand_end_ts
  ),
  aircraft_on_stand_start_ts: toMilliSecondsOrNull(
    params.aircraft_on_stand_start_ts
  ),
  aircraft_start_ts: toMilliSecondsOrNull(params.aircraft_start_ts),
  flight_ardt: toMilliSecondsOrNull(params.flight_ardt),
  aldt: toMilliSecondsOrNull(params.aldt),
  eibt: toMilliSecondsOrNull(params.eibt),
  eobt: toMilliSecondsOrNull(params.eobt),
  ltd: toMilliSecondsOrNull(params.ltd),
  pobt: toMilliSecondsOrNull(params.pobt),
  sibt: toMilliSecondsOrNull(params.sibt),
  sobt: toMilliSecondsOrNull(params.sobt),
  tobt: toMilliSecondsOrNull(params.tobt),
  initial_eobt: toMilliSecondsOrNull(params.initial_eobt),
  tsat: toMilliSecondsOrNull(params.tsat),
  asat: toMilliSecondsOrNull(params.asat),
  asrt: toMilliSecondsOrNull(params.asrt),
  aircraft_or_pushback_ts: toMilliSecondsOrNull(params.aircraft_or_pushback_ts),
  aircraft_ready_ts: toMilliSecondsOrNull(params.aircraft_ready_ts),
  predicted_aircraft_ready_ts: toMilliSecondsOrNull(
    params.predicted_aircraft_ready_ts
  ),
  apbg: toMilliSecondsOrNull(params.apbg),
  ctot: toMilliSecondsOrNull(params.ctot),
  extra: toMilliSecondsOrNull(params.extra),
  aibt: toMilliSecondsOrNull(params.aibt),
  aobt: toMilliSecondsOrNull(params.aobt),
  cobt: toMilliSecondsOrNull(params.cobt),
  eldt: null,
});

// TODO: should be implemented on backend
export const generateExtendedTurnaroundParams = (
  params: TurnaroundParams,
  overrides: TAirportConfig["turnParamsSource"],
  detections: DetectionPartial[]
): TurnaroundParams => {
  const turnParamsSourceEntries = typedEntries(overrides);
  const result: TurnaroundParams = {
    ...params,
  };

  // TODO: generateExtendedTurnaroundParams must be implemented on backend
  // because we cannot probe detections for all turns simultaneously
  if (turnParamsSourceEntries.length) {
    const groupedDetections = groupBy(detections, (v) => v.type);
    Object.entries(groupedDetections).forEach(([_, sameTypeDetections]) => {
      // Join several detections into one
      const { startType, endType, start, end } = sameTypeDetections.reduce<
        Pick<DetectionPartial, "startType" | "endType" | "start" | "end">
      >(
        (accum, curr) => {
          accum.startType = curr.startType;
          accum.endType = curr.endType;

          accum.start = Math.min(curr.start, accum.start);
          if (curr.end === null) {
            accum.end = null;
          } else if (accum.end !== null) {
            accum.end = Math.max(accum.end, curr.end);
          }

          return accum;
        },
        {
          startType: "",
          endType: "",
          start: Number.POSITIVE_INFINITY,
          end: 0,
        }
      );

      turnParamsSourceEntries.forEach(([flightStatusKey, eventType]) => {
        if (eventType === startType) {
          result[flightStatusKey] = start;
        } else if (eventType === endType) {
          result[flightStatusKey] = end;
        }
      });
    });
  }

  return result;
};

export const getProgressColor = (
  params: TurnaroundParams,
  isHistoric: boolean,
  airportConfig: TAirportConfig
): TurnProgressColor => {
  const {
    turnaroundProgressConfig: { prediction, reference, offsetInSeconds },
  } = airportConfig;

  const predictionValue = params[prediction];
  const referenceValue = params[reference];
  const offsetInMilliseconds = offsetInSeconds * 1000;

  if (isHistoric || !predictionValue || !referenceValue) {
    return "grey";
  }

  const referenceWithOffset = referenceValue + offsetInMilliseconds;

  // .Red if Prediction is > (Reference+Offset)
  if (dropSeconds(predictionValue) > dropSeconds(referenceWithOffset)) {
    return "red";
  }

  return "green";
};

export const getCountdownValue = (
  params: TurnaroundParams,
  airportConfig: TAirportConfig
): number | null => {
  const {
    turnaroundProgressConfig: { countdownSource },
  } = airportConfig;

  const value = params[countdownSource];
  if (!value) {
    return null;
  }

  // If countdown reaches 0, return null
  return Math.max(value - getNow(), 0) || null;
};
