import React, { lazy, Suspense, useCallback, useEffect } from "react";
import { AirfoilRoot, Spinner } from "@frontend/airfoil";
import { HotkeysProvider } from "react-hotkeys-hook";

import "./style.scss";

const LazyGlobalProvider = lazy(() =>
  import("./GlobalProvider").then(({ GlobalProvider }) => ({
    default: GlobalProvider,
  }))
);

export const App = () => {
  // TODO: Get rid of this
  const onRootResize = useCallback(() => {
    const root = document.getElementById("root");
    if (!root) {
      return;
    }
    root.style.height = window.innerHeight + "px";
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onRootResize);

    return () => {
      window.removeEventListener("resize", onRootResize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AirfoilRoot>
      <HotkeysProvider>
        <Suspense fallback={<Spinner />}>
          <LazyGlobalProvider />
        </Suspense>
      </HotkeysProvider>
    </AirfoilRoot>
  );
};
