import { camelCase } from "lodash";
import clamp from "lodash/clamp";
import round from "lodash/round";

export const isFiniteNumber = <T>(
  value: T
): value is T extends number ? T : never => {
  return typeof value === "number" && Number.isFinite(value);
};

export const isArray = <T>(
  value: T
): value is T extends Array<any> ? T : never => {
  return Array.isArray(value);
};

export const isFunction = <T>(
  value: T
): value is T extends Function ? T : never => {
  return typeof value === "function";
};

export const toSafeCssNumber = (
  value: number,
  precision = 4,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER
) => {
  if (typeof value !== "number") {
    return 0;
  }

  const result = round(clamp(value, min, max), precision);

  return isNaN(result) || !isFiniteNumber(result) ? 0 : result;
};

// Creates an FNV hash key based on string value
export const fnv1a = (value: string) => {
  if (typeof value !== "string") {
    throw new TypeError("Input value must be a string.");
  }

  const FNV_prime = 0x01000193; // 16777619
  let hash = 0x811c9dc5; // 2166136261 (FNV offset basis)

  for (let i = 0; i < value.length; i++) {
    hash ^= value.charCodeAt(i);
    hash *= FNV_prime;
    hash >>>= 0; // Обеспечивает, что hash остается 32-битным беззнаковым целым
  }

  let hashString = hash.toString(16);

  if (hashString.length < 8) {
    hashString = "0".repeat(8 - hashString.length) + hashString;
  }

  return hashString;
};

export function randomId(): string {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0] || 0;
  return uint32.toString(16);
}

export const predicateNonNullable = <I>(item: I): item is NonNullable<I> => {
  return Boolean(item);
};

export function camelCaseKeys(obj: any): any {
  if (obj === null) {
    return null;
  }
  if (Array.isArray(obj)) {
    return obj.map(camelCaseKeys);
  }

  const res: any = {};
  Object.entries(obj).forEach(([key, value]) => {
    const newKey = camelCase(key);
    res[newKey] = typeof value === "object" ? camelCaseKeys(value) : value;
  });
  return res;
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function convertObjUtcToMilliseconds<T extends Record<string, any>>(
  obj: T
) {
  const result = { ...obj };

  for (const [key, value] of Object.entries(obj)) {
    if (Object.hasOwn(result, key)) {
      result[key as keyof T] =
        typeof result[key] === "number" ? value * 1000 : value;
    }
  }

  return result;
}

export const getCb = (fn?: (e?: Event) => void) =>
  fn ? (e: Event) => fn(e) : null;

export const tsLowerCase = <T extends string>(v: T): Lowercase<T> =>
  v.toLocaleLowerCase() as Lowercase<T>;

export const isWritable = <T extends Record<keyof T, T[keyof T]>>(
  obj: T,
  key: keyof T
) => {
  const desc =
    Object.getOwnPropertyDescriptor(obj, key) ||
    Object.getOwnPropertyDescriptor(Object.getPrototypeOf(obj), key);

  return Boolean(desc?.writable);
};

export const isPropertyOfTargetObject = <T extends Object>(
  targetObject: T,
  key: PropertyKey
): key is keyof T => Boolean(key) && key in targetObject;

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function filterNonNullish<T>(array: (T | null | undefined)[]): T[] {
  return array.filter(isDefined);
}

export function typedFromEntries<K extends string | number | symbol, V>(
  entries: [K, V][]
): Record<K, V> {
  return Object.fromEntries(entries) as Record<K, V>;
}

// TODO: write proper ObjectConstructor.entries type
// Omit<T, symbol> is used to exclude symbols the type
export function typedEntries<T extends object>(
  obj: T
): [keyof Omit<T, symbol>, T[keyof Omit<T, symbol>]][] {
  return Object.entries(obj) as [
    keyof Omit<T, symbol>,
    T[keyof Omit<T, symbol>],
  ][];
}

export const safeMapGet = <K, V>(map: Map<K, V>, key: K, fallback: V): V => {
  return map.get(key) ?? fallback;
};
