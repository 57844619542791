import { AxiosRequestConfig } from "axios";
import { getConfig } from "@di";
import { DetectionPartial, DetectionDetailed } from "@entities/detection";
import {
  ApiPostMiddlewareFunction,
  ApiPreMiddlewareFunction,
  TimelineResult,
  TurnaroundResult,
  TurnaroundsResult,
  TurnaroundTimelineResult,
} from "../types";

export const handrailsRules = [
  {
    outerOp: "back_left_catering_truck",
    innerOp: "back_left_catering_guard_rail",
    label: "Aft catering handrail not deployed",
    resultOpType: "aft_catering_handrail_not_deployed",
  },
  {
    outerOp: "back_right_catering_truck",
    innerOp: "back_right_catering_guard_rail",
    label: "Aft port catering handrail not deployed",
    resultOpType: "aft_port_catering_handrail_not_deployed",
  },
  {
    outerOp: "back_right_catering_truck",
    innerOp: "one_back_right_catering_guard_rail",
    label: "Aft port catering handrail not deployed",
    resultOpType: "aft_port_catering_handrail_not_deployed",
  },
  {
    outerOp: "front_catering_truck",
    innerOp: "front_catering_guard_rail",
    label: "Fwd catering handrail not deployed",
    resultOpType: "fwd_catering_handrail_not_deployed",
  },
  {
    outerOp: "front_catering_truck",
    innerOp: "one_front_catering_guard_rail",
    label: "Fwd catering handrail not deployed",
    resultOpType: "fwd_catering_handrail_not_deployed",
    stands: ["sea-a2", "sea-c9", "sea-d1", "sea-d2"],
  },
  {
    outerOp: "back_left_catering_truck",
    innerOp: "one_back_left_catering_guard_rail",
    label: "Aft catering handrail not deployed",
    resultOpType: "aft_catering_handrail_not_deployed",
  },
];

const getIncludedStands = (targetDetectionType: string) => {
  const { detectionTypesMap } = getConfig();
  return detectionTypesMap[targetDetectionType]?.includedStands || [];
};

const getTurnFromResp = (
  resp: TimelineResult | TurnaroundTimelineResult | TurnaroundResult
) => {
  return "turnaround" in resp ? resp.turnaround : resp.turnarounds[0];
};

export const handrailsMiddleware: ApiPostMiddlewareFunction<
  TimelineResult | TurnaroundTimelineResult | TurnaroundResult | null
> = (resp) => {
  if (!resp) {
    return resp;
  }

  let { detections } = resp;

  const getDetection = function getDetection<
    T extends DetectionPartial | DetectionDetailed,
  >(id: string, label: string, start: number, end: T["end"], detection: T): T {
    const newDetection = detection.clone() as T;

    newDetection.type = label.toLowerCase().split(" ").join("_");
    newDetection.id = id;
    newDetection.startLabel = { "en-US": label + " (start)" };
    newDetection.label = { "en-US": label };
    newDetection.confidence = 1;
    newDetection.origin = "middleware";
    newDetection.originalId = detection.id;
    newDetection.start = start;
    newDetection.end = end;
    newDetection.startType = "handrails_not_presented_event";
    newDetection.endType = "handrails_not_presented_event";
    newDetection.endState = "FINAL";
    newDetection.endLabel = { "en-US": label + " (end)" };

    return newDetection;
  };

  function isValid(d: DetectionPartial, maxWidth: number) {
    return (d.end || Date.now()) - d.start > maxWidth;
  }

  const PADDING = 60000;

  const turn = getTurnFromResp(resp);

  if (!turn || turn.start < 1655337600000) {
    return resp;
  }

  const standId = turn.standId;

  const exclude = handrailsRules
    .filter((r) => getIncludedStands(r.innerOp).includes(standId))
    .map((r) => r.innerOp);

  if (!exclude.length) {
    return resp;
  }

  detections = detections.reduce<(DetectionPartial | DetectionDetailed)[]>(
    function (res, outerOp, index, array) {
      if (exclude.includes(outerOp.type)) {
        return res;
      }

      res.push(outerOp);
      const validRules = handrailsRules.filter(
        (r) =>
          r.outerOp === outerOp.type &&
          getIncludedStands(r.innerOp).includes(standId)
      );

      if (!validRules.length || !isValid(outerOp, PADDING * 2)) {
        return res;
      }

      const min = outerOp.start + PADDING;
      const max = outerOp.end ? outerOp.end - PADDING : Infinity;

      validRules.forEach(function (rule) {
        const diffItems = array
          .filter(function (d) {
            if (d.type !== rule.innerOp) {
              return false;
            }

            return d.start < max && (d.end || Infinity) > min;
          })
          .sort(function (i1, i2) {
            return i1.start - i2.start;
          });

        let lastEnd: DetectionPartial["end"] = min;
        diffItems.forEach(function (d, index) {
          if (typeof lastEnd === "number") {
            const newD = getDetection(
              outerOp.id + "_" + index,
              rule.label,
              lastEnd,
              d.start,
              d
            );
            if (isValid(newD, 30000)) {
              res.push(newD);
            }
          }
          lastEnd = d.end;
        });

        if (lastEnd && lastEnd < max) {
          const newD = getDetection(
            outerOp.id + "_" + diffItems.length,
            rule.label,
            lastEnd,
            outerOp.end ? max : null,
            outerOp
          );

          if (isValid(newD, 30000)) {
            res.push(newD);
          }
        }
      });

      return res;
    },
    []
  );

  return { ...resp, detections };
};

export const wingWalkersMiddleware: ApiPostMiddlewareFunction<
  TimelineResult | TurnaroundTimelineResult | TurnaroundResult | null
> = (resp) => {
  if (!resp) {
    return resp;
  }

  const leftWingwalkerStands = getIncludedStands("left_wingwalker");
  const rightWingwalkerStands = getIncludedStands("right_wingwalker");

  let { detections } = resp;
  const turn = getTurnFromResp(resp);
  const standId = turn?.standId || "";

  if (
    !turn ||
    turn.start < 1655337600000 ||
    ![...leftWingwalkerStands, ...rightWingwalkerStands].includes(standId)
  ) {
    return resp;
  }

  let aircraftDetection: DetectionPartial | undefined;
  let onStandsDetection: DetectionPartial | undefined;
  const leftWingwalkers: DetectionPartial[] = [];
  const rightWingwalkers: DetectionPartial[] = [];
  detections = detections.reduce<DetectionPartial[]>(function (accum, curr) {
    if (curr.type === "left_wingwalker") {
      leftWingwalkers.push(curr);
      return accum;
    }

    if (curr.type === "right_wingwalker") {
      rightWingwalkers.push(curr);
      return accum;
    }

    if (
      curr.type === "aircraft" &&
      (!aircraftDetection || aircraftDetection.start < curr.start)
    ) {
      aircraftDetection = curr;
    }

    if (
      curr.type === "aircraft_on_stand" &&
      (!onStandsDetection || onStandsDetection.start < onStandsDetection.start)
    ) {
      onStandsDetection = curr;
    }

    accum.push(curr);
    return accum;
  }, []);

  const defaultValue = { ...resp, detections };

  if (!aircraftDetection || !aircraftDetection.end || !onStandsDetection) {
    return defaultValue;
  }

  const min = aircraftDetection.end;
  const max = onStandsDetection.end ? onStandsDetection.end + 120000 : Infinity;

  const common: Partial<DetectionPartial> = {
    confidence: 1,
    start: min,
    startType: "wingwalker_not_presented_event",
    origin: "middleware",
    // startConfidence: 1,
  };

  if (turn.end) {
    common.end = turn.end;
    common.endType = "wingwalker_not_presented_event";
    common.endState = "FINAL";
    common.confidence = 1;
  }

  const detectionBetweenMinAndMax = (d: DetectionPartial) =>
    d.start < max && (!d.end || d.end > min);
  if (!leftWingwalkers.some(detectionBetweenMinAndMax)) {
    const newDetection = new DetectionPartial(null, "middleware", getConfig());
    Object.assign(newDetection, {
      ...common,
      type: "left_wingwalker_not_presented",
      id: turn.id + "_no_left_wingwalker",
      startLabel: "Starboard wingwalker not present",
      label: "Starboard wingwalker not present",
    });
    detections.push(newDetection);
  }

  if (!rightWingwalkers.some(detectionBetweenMinAndMax)) {
    const newDetection = new DetectionPartial(null, "middleware", getConfig());
    Object.assign(newDetection, {
      ...common,
      type: "right_wingwalker_not_presented",
      id: turn.id + "_no_right_wingwalker",
      startLabel: "Port wingwalker not present",
      label: "Port wingwalker not present",
    });
    detections.push(newDetection);
  }

  return { ...resp, detections };
};

export const extendTimelineRequestRange: ApiPreMiddlewareFunction<
  AxiosRequestConfig,
  AxiosRequestConfig
> = (conf) => {
  if (conf.params?.end_ts) {
    conf.params.end_ts += 75;
  }

  return conf;
};

export const seaFixB7bTurn: ApiPostMiddlewareFunction<TurnaroundsResult> = (
  resp
) => {
  console.debug("[middleware: seaFixB7bTurns]", resp);
  resp.turnarounds.forEach((t) => {
    if (t.standId === "sea-b7") {
      t.originalStandId = "sea-b7b";
      console.debug(
        `[middleware: seaFixB7bTurns] patched turnaround "${t.id}"\noriginalStandId: "${t.originalStandId}" -> "sea-b7b"`,
        t
      );
    }
  });

  return resp;
};
