import React, { PropsWithChildren, useEffect, useState } from "react";
import { Spinner } from "@frontend/airfoil";
import OIDCApp from "@shared/components/OIDCApp";

import { OpenIdConfig } from "@models/common";
import { reportError, reportMessage } from "@services/logger";
import { InitialApi } from "@api";
import { ErrorMessage } from "@ui/Common/ErrorMessage";
import { readFromLocalStorage } from "@services/localStorage";
import { createLogger } from "@services/createLogger";

const IS_OIDC_DEBUG = readFromLocalStorage("__ENABLE_OIDC_DEBUG", false).value;

const logger = createLogger("App");

export const OIDCAppGate = ({ children }: PropsWithChildren) => {
  const [error, setError] = useState<Error | null>(null);
  const [creds, setCreds] = useState<OpenIdConfig>();

  const onError = (error: Error) => {
    setError(error);
    reportError(error);
  };

  useEffect(() => {
    new InitialApi()
      .getInitialOpenIdCreds()
      .then((openIdConfig) => {
        const newCredentials = { ...openIdConfig };

        if (process.env.CLIENT_ID) {
          newCredentials.client_id = process.env.CLIENT_ID;

          logger.log(`Using CLIENT_ID from ENV: ${process.env.CLIENT_ID}`);
        } else {
          logger.log(`Using CLIENT_ID from API: ${newCredentials.client_id}`);
        }

        setCreds(newCredentials);
      })
      .catch(onError);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <ErrorMessage message={error.message} withRefreshButton={true} />;
  }

  if (!creds) {
    return <Spinner />;
  }

  return (
    <OIDCApp
      onError={onError}
      authority={creds.authority}
      clientId={creds.client_id}
      customLogger={IS_OIDC_DEBUG ? console : undefined}
      logLevel={IS_OIDC_DEBUG ? "debug" : "error"}
      onAddSilentRenewError={() => {
        reportMessage(`Reload page due to SilentRenewError`);
      }}
      onSigninCallback={(urlToRestore) => {
        window.history.replaceState(null, "", urlToRestore);
      }}
    >
      {children}
    </OIDCApp>
  );
};
