import { TAirportConfig } from "@frontend/configuration";
import { FutureFlight } from "@models/flightInfo";
import { toMilliSecondsOrNull } from "@services/time";
import { TFlightsResponse } from "@api/configs/flightsArrivalsDepartures";
import {
  createTurnaroundParams,
  parseTurnParams,
  prepareTurnaroundParams,
} from "@entities/turnaround/helpres";

type TRawFlight = TFlightsResponse[number];

export const parseRawFlight = (
  flight: TRawFlight,
  turnParamSource: TAirportConfig["turnParamsSource"]
) => {
  let parsedParams = createTurnaroundParams();

  parsedParams.sibt = toMilliSecondsOrNull(flight.scheduled_on_block_utc);
  parsedParams.eibt = toMilliSecondsOrNull(flight.estimated_on_block_utc);

  parsedParams.sobt = toMilliSecondsOrNull(flight.scheduled_off_block_utc);
  parsedParams.eobt = toMilliSecondsOrNull(flight.estimated_off_block_utc);

  // Overwrite flight's params with turn's params if they are present
  if (flight.turnaround_params) {
    parsedParams = {
      ...parsedParams,
      ...parseTurnParams(flight.turnaround_params),
    };
  }

  const preparedParams = prepareTurnaroundParams(parsedParams, turnParamSource);

  const result: FutureFlight = {
    id: flight.data_hash,
    standId: flight.stand_id || "",
    fullFlightNumber: flight.full_flight_number || "",
    flightNumber: flight.flight_number || "",
    companyIata: flight.company_iata,
    departureAirport: flight.departure_airport || null,
    arrivalAirport: flight.arrival_airport || null,
    aircraftType: flight.aircraft_type || null,
    aircraftRegNumber: flight.aircraft_reg_number || null,
    rawFlightStatus: flight.raw_flight_status || null,

    params: preparedParams,
    turnId: flight.turnaround_id || null,
    isArrival: flight.flight_type === "arrival" || false,
  };

  return result;
};
