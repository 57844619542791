import flatten from "lodash/flatten";
import sortBy from "lodash/sortBy";
import uniqWith from "lodash/uniqWith";
import { chain, orderBy } from "lodash";
import moment from "moment";

import { getConfig } from "@di";
import { ApronAlert } from "@models/apronAlert";
import { CameraOutage } from "@models/cameraOutage";
import { IncidentConfig } from "@models/incidentConfig";
import { Pts } from "@models/pts";
import { TPOBTHistoryItem } from "@models/pobtHistory";

import { camelCaseKeys, isFiniteNumber } from "@services/utils";
import { filterItemsByStandPatterns } from "@services/data/stand";
import { getNow, toMilliSeconds, toMilliSecondsOrNull } from "@services/time";
import { AirportConfigStore } from "@stores/AirportConfigStore";
import { AssaiaUser } from "@shared/components/OIDCApp";
import {
  IncidentsResponse,
  TSearchTurnaroundsResponse,
  TStandTurnaroundsResponse,
} from "@api/configs";
import { TTurnaroundDTO } from "@entities/turnaround/types";
import {
  TurnaroundsResult,
  TimelineResult,
  TAddAlertsResult,
} from "@api/types";
import { Turnaround } from "@entities/turnaround";
import { DetectionPartial } from "@entities/detection";
import { TPOBTHistoryItemResponse } from "@api/apiSpec/missing-types";
import { parseBBoxes } from "./parseBBoxes";

export const parseTurnarounds = (
  data: TStandTurnaroundsResponse | TSearchTurnaroundsResponse,
  user: AssaiaUser,
  config: AirportConfigStore
): TurnaroundsResult => {
  const { turnaroundsStartTimestamp, turnaroundsVisibleRangeSizeByRole } =
    config;
  let minTurnEnd = 0;
  if (turnaroundsVisibleRangeSizeByRole) {
    (user.profile.roles || []).forEach((role) => {
      let restriction = turnaroundsVisibleRangeSizeByRole[role.toLowerCase()];
      if (restriction) {
        restriction = Date.now() - toMilliSeconds(restriction);
        minTurnEnd = Math.max(minTurnEnd, restriction);
      }
    }, []);
  }

  if (minTurnEnd) {
    data = data.filter((item) => {
      const turnEnd = toMilliSecondsOrNull(item.end_ts);
      return !turnEnd || turnEnd >= minTurnEnd;
    }) as typeof data;
  }

  if (turnaroundsStartTimestamp) {
    data = data.filter((item) => {
      const standId = item.stand_id;
      const start = toMilliSecondsOrNull(item.start_ts) as number;
      const minStart = isFiniteNumber(turnaroundsStartTimestamp)
        ? turnaroundsStartTimestamp
        : turnaroundsStartTimestamp[standId] || 0;

      return start >= toMilliSeconds(minStart);
    }) as typeof data;
  }

  const turnarounds = chain(data)
    // TODO remove as TTurnaroundDTO
    .map((d) => new Turnaround(d as TTurnaroundDTO, config))
    .compact()
    .orderBy(["start"], ["desc"])
    .value();
  const fetchedCount = data.length;

  return {
    turnarounds,
    fetchedCount,
  };
};

export function parseDetections(
  data: any[],
  standId: string,
  parseItem: (d: any) => DetectionPartial
) {
  let detections = data.map(parseItem);
  if (window.ignoredOperations) {
    detections = detections.filter(
      (d: DetectionPartial) => !window.ignoredOperations.includes(d.type)
    );
  }

  const {
    minOperationConfidence,
    ignoreMinOperationConfidenceOps,
    mergableOperations: mergeOperationsFlag,
    turnaroundsStartTimestamp,
  } = getConfig();
  if (minOperationConfidence && ignoreMinOperationConfidenceOps) {
    detections = detections.filter(
      (d: DetectionPartial) =>
        ignoreMinOperationConfidenceOps.includes(d.type) ||
        (d.confidence && d.confidence >= minOperationConfidence)
    );
  }
  if (mergeOperationsFlag) {
    detections = mergeOperations(detections);
  }

  const ts =
    typeof turnaroundsStartTimestamp === "object" && turnaroundsStartTimestamp
      ? turnaroundsStartTimestamp[standId]
      : turnaroundsStartTimestamp;
  if (ts) {
    detections = detections.filter((d) => d.start >= toMilliSeconds(ts));
  }

  return sortBy(detections, "start");
}

export function parseCameraOutage(data: any): CameraOutage {
  return {
    id: data.id,
    start: toMilliSecondsOrNull(data.start_ts) as number,
    end: toMilliSecondsOrNull(data.end_ts),
    camera: data.camera_id,
  };
}

export function mergeOperations(detections: DetectionPartial[]) {
  const { mergableOperations } = getConfig();
  const mergeableKeys = flatten(Object.values(mergableOperations));
  const newOperations = [
    ...detections.filter((o) => !mergeableKeys.includes(o.type)),
  ];

  for (const key in mergableOperations) {
    let detectionsToMerge: DetectionPartial[] = detections.filter(
      ({ type }) => mergableOperations[key]?.includes(type)
    );
    if (!detectionsToMerge.length) {
      continue;
    }

    detectionsToMerge = sortBy(detectionsToMerge, (d) => d.start);

    let detection;
    while ((detection = detectionsToMerge.shift())) {
      const range: [number, number] = [
        detection.start,
        detection.end || getNow(),
      ];
      const newDetection = detection.clone();

      for (const detection2 of [...detectionsToMerge]) {
        const range2: [number, number] = [
          detection2.start,
          detection2.end || getNow(),
        ];
        if (!(range[0] <= range2[1] && range[1] >= range2[0])) {
          break;
        }

        if (range2[1] > range[1]) {
          newDetection.id = `${detection.id}-${detection2.id}`;
          newDetection.end = detection2.end;
          newDetection.endType = detection2.endType;
          newDetection.endLabel = detection2.endLabel;
        }
        detectionsToMerge = detectionsToMerge.filter(
          (o) => o.id !== detection2.id
        );
      }
      newOperations.push(newDetection);
    }
  }
  return newOperations;
}

export function parseAlert(data: any): ApronAlert {
  const alert: ApronAlert = camelCaseKeys(data);
  alert.ts *= 1000;
  alert.fake = false;
  alert.data = camelCaseKeys(data.data);

  alert.data.incidentType = data.config.incident_type;
  // RE made hack, so we must fix incident_type here
  if (data.config.incident_type === "weighted-safety-event") {
    alert.data.incidentType = "safety-event";
  }

  alert.data.type = data.data.strategy;

  alert.config = parseIncidentConfig(data.config);

  if (alert.inboundFlightNumber && alert.inboundCompanyIata) {
    alert.inboundFlightNumber =
      alert.inboundCompanyIata + alert.inboundFlightNumber;
  }
  if (alert.outboundFlightNumber && alert.outboundCompanyIata) {
    alert.outboundFlightNumber =
      alert.outboundCompanyIata + alert.outboundFlightNumber;
  }

  if (alert.data.incidentType === "safety-event") {
    const strategy = data.data.strategy;
    const newStrategies = [
      "multi-config-safety-event",
      "catering-handrail-safety-event",
      "safety-event",
      "intersection-safety-event",
      "stopline-violation-safety-event",
    ];
    if (newStrategies.includes(strategy)) {
      const safetyEvents = data.data.safety_events || [];
      alert.data.safetyEvents = safetyEvents.map(
        (v: { op_name: string; timestamp: number; bbox_ranges: any }) => ({
          opName: v.op_name,
          timestamp: v.timestamp * 1000,
          bboxRanges: parseBBoxes(v.bbox_ranges),
        })
      );
    } else {
      // Get rid of it after https://git.srv.assaia.com/Apron-SU/apron-su/-/issues/10589
      const timeStamps = data.data.safety_event_timestamps || [];
      alert.data.safetyEvents = timeStamps.map((ts: number) => ({
        timestamp: ts * 1000,
        bboxRanges: {},
      }));
    }
  }
  return alert;
}

export const parseIncidentConfig = (data: any): IncidentConfig => {
  const config: IncidentConfig = camelCaseKeys(data);
  config.data = camelCaseKeys(config.data);
  config.data.type = data.data.strategy;
  config.data.incidentType = data.incident_type;

  if (data.detector_strategy === "weighted-safety-event") {
    // RE made hack, so we must fix incident_type here
    config.data.incidentType = "safety-event";
    config.incidentType = "safety-event";
  }

  return config;
};

export function parsePts(data: any): Pts | null {
  if (!data) {
    return null;
  }

  const newData = camelCaseKeys(data);
  newData.schedules = data.schedules.map((v: any) => camelCaseKeys(v));

  const toMs = (v: number | null) => (v !== null ? v * 1000 : null);

  newData.schedules = uniqWith(
    newData.schedules,
    (a: any, b: any) => a.id === b.id
  );
  newData.schedules = newData.schedules.map((s: any) => ({
    ...s,
    start: {
      referencePoint: s.start.referencePoint || "sobt",
      idealTime: s.start.idealTime * 1000,
      redInterval: {
        start: toMs(s.start.redInterval.start),
        end: toMs(s.start.redInterval.end),
      },
      orangeInterval: {
        start: toMs(s.start.orangeInterval.start),
        end: toMs(s.start.orangeInterval.end),
      },
    },
    end: {
      referencePoint: s.end.referencePoint || "sobt",
      idealTime: s.end.idealTime * 1000,
      redInterval: {
        start: toMs(s.end.redInterval.start),
        end: toMs(s.end.redInterval.end),
      },
      orangeInterval: {
        start: toMs(s.end.orangeInterval.start),
        end: toMs(s.end.orangeInterval.end),
      },
    },
  }));

  return newData;
}

export function parseTimestampsDict(
  data: Record<string, number | null | undefined>
) {
  const result: Record<string, number> = {};
  Object.entries(data).forEach(([key, val]) => {
    val = toMilliSecondsOrNull(val || null);
    if (val) {
      result[key] = val;
    }
  });
  return result;
}

export const parseTimestamps = (
  data: any
): Pick<
  TimelineResult,
  "absoluteTime" | "lastImageTimestamp" | "inferenceTimestamp"
> => {
  const { last_prediction_ts, last_image_ts } = data;
  const { current_ts } = data;

  Object.entries(last_prediction_ts).forEach(([cam, ts]) => {
    if (!last_image_ts[cam]) {
      last_image_ts[cam] = ts;
    }
  });

  return {
    inferenceTimestamp: parseTimestampsDict(last_prediction_ts),
    lastImageTimestamp: parseTimestampsDict(last_image_ts),
    absoluteTime: current_ts * 1000,
  };
};

export const parseIncidents =
  (
    startTs: number,
    filterAlertsFromOtherAirports: boolean,
    standPatterns: string[]
  ) =>
  (response: IncidentsResponse): TAddAlertsResult => {
    const { incidents = [], total_count = 0 } = response;
    let rawConfigs = incidents;

    if (startTs) {
      rawConfigs = rawConfigs.filter(
        // TODO: make sure if type of ts is correct (described as a string in the API specs)
        ({ ts }) => <number>(<unknown>ts) >= startTs
      );
    }

    const result: TAddAlertsResult = {
      unifiedIncidents: [],
      safetyAlertIncidents: [],
      safetyEventIncidents: [],
      actualAlertCount: total_count,
    };

    for (const raw of rawConfigs) {
      const parsedData = parseAlert(raw);
      const data = parsedData.data;

      switch (data.incidentType) {
        case "unified": {
          result.unifiedIncidents.push({
            ...parsedData,
            data,
          });
          break;
        }

        case "safety-alert": {
          result.safetyAlertIncidents.push({
            ...parsedData,
            data,
          });
          break;
        }

        case "safety-event": {
          result.safetyEventIncidents.push({
            ...parsedData,
            data,
          });
          break;
        }
      }
    }

    if (filterAlertsFromOtherAirports) {
      result.unifiedIncidents = filterItemsByStandPatterns(
        result.unifiedIncidents,
        ({ standId }) => standId,
        standPatterns
      );
      result.safetyAlertIncidents = filterItemsByStandPatterns(
        result.safetyAlertIncidents,
        ({ standId }) => standId,
        standPatterns
      );
      result.safetyEventIncidents = filterItemsByStandPatterns(
        result.safetyEventIncidents,
        ({ standId }) => standId,
        standPatterns
      );
    }

    return result;
  };

export const parsePOBTHistory = (
  data: TPOBTHistoryItemResponse
): TPOBTHistoryItem[] => {
  const parseTsString = (ts: string) => {
    const date = moment.utc(ts);
    return date.valueOf();
  };

  const result = data.map((item) => ({
    ts: parseTsString(item.created_at),
    pobt: item.predicted_obt ? parseTsString(item.predicted_obt) : null,
    eobt: item.eobt ? parseTsString(item.eobt) : null,
  }));

  return orderBy(result, "ts", "asc");
};
