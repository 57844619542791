import { Localized } from "@frontend/configuration";
import { action, makeObservable, observable } from "mobx";
import { captureException } from "@sentry/browser";
import { AirportConfigStore } from "@stores/AirportConfigStore";
import { Entity } from "@models/entity";
import { di } from "@di";
import { IS_DEV } from "@constants/common";
import { toMilliSeconds, toMilliSecondsOrNull } from "@services/time";
import { parseBBoxes } from "@api/parsers/parseBBoxes";
import { BBox, DetectionPartialDTO, DetectionDetailDTO } from "./types";

export type DetectionEvent = {
  id: string;
  type: string;
  timestamp: number;
  label: Localized | null;
  confidence: number | null;
  detectionGap: number | null;
  detection: DetectionPartial;
};

// TODO rename to better name
export class DetectionPartial implements Entity {
  id = "";
  confidence: number | null = null;
  type: string = "";
  label: Localized = { "en-GB": "" };
  start: number = 0;
  startType: string = "";
  startLabel: Localized = { "en-GB": "" };
  end: number | null = null;
  endType: string | null = null;
  endLabel: Localized | null = null;
  endState: string | null = null;
  startState: string = "";
  startConfidence: number | null = null;
  startDetectionGap: number | null = null;
  endConfidence: number | null = null;
  endDetectionGap: number | null = null;
  turnaroundId: string | null = null;
  /**
   * Can be set for UI generated detections
   */
  originalId: string | null = null;
  /**
   * For debug purposes
   */
  rawData: DetectionPartialDTO | null = null;

  constructor(
    dto: DetectionPartialDTO | null,
    public origin: "api" | "pts" | "middleware" | "ui",
    protected _airportConfigStore: AirportConfigStore
  ) {
    if (dto) {
      this.update(dto);
    }

    makeObservable(this, {
      id: observable,
      confidence: observable,
      type: observable,
      label: observable,
      start: observable,
      startType: observable,
      startLabel: observable,
      end: observable,
      endType: observable,
      endLabel: observable,
      endState: observable,
      originalId: observable,
      update: action,
      startState: observable,
      startConfidence: observable,
      startDetectionGap: observable,
      endConfidence: observable,
      endDetectionGap: observable,
    });
  }

  // TODO try more narrow type
  get events(): DetectionEvent[] {
    const startEvent: DetectionEvent = {
      id: this.id + "-start",
      type: this.startType,
      timestamp: this.start,
      label: this.startLabel,
      confidence: ("startConfidence" in this && this.startConfidence) || null,
      detectionGap:
        ("startDetectionGap" in this && this.startDetectionGap) || null,
      detection: this,
    };

    if (this.end && this.endType) {
      const endEvent: DetectionEvent = {
        id: this.id + "-end",
        type: this.endType,
        timestamp: this.end,
        label: this.endLabel || null,
        confidence: "endConfidence" in this ? this.endConfidence || null : null,
        detectionGap:
          "endDetectionGap" in this ? this.endDetectionGap || null : null,
        detection: this,
      };

      return [startEvent, endEvent];
    }

    return [startEvent];
  }

  get typeInfo() {
    const { detectionTypesMap } = this._airportConfigStore;
    return detectionTypesMap[this.type];
  }

  get hasOperationRole(): boolean {
    const { typeInfo } = this;
    return typeInfo?.roles.includes("operation") || false;
  }

  update(data: DetectionPartialDTO) {
    this.rawData = data;
    this.type = data.op_name;

    const { typeInfo } = this;
    if (!typeInfo) {
      const error = new Error(
        "[API#parsePartialDetection] Missing meta for " + data.op_name
      );

      captureException(error);

      if (IS_DEV || di.resolve("configOverrides").isDebug) {
        console.error(error);
      }
    }

    this.id = data.id;
    this.confidence = data.confidence || null;
    this.start = toMilliSeconds(data.start_ts);
    this.end = toMilliSecondsOrNull(data.end_ts);
    this.startType = data.start_type;
    this.endType = data.end_type || null;
    this.startLabel = typeInfo?.startEventLabel || {
      "en-GB": data.start_type,
    };
    this.endLabel = typeInfo?.endEventLabel || { "en-GB": data.end_type };

    this.label = typeInfo?.label || { "en-GB": data.op_name };
    this.origin = "api";

    if ("start_confidence" in data) {
      this.startConfidence = data.start_confidence || null;
    }
    if ("end_confidence" in data) {
      this.endConfidence = data.end_confidence || null;
    }
    if ("start_detection_gap" in data) {
      this.startDetectionGap = toMilliSecondsOrNull(data.start_detection_gap);
    }
    if ("end_detection_gap" in data) {
      this.endDetectionGap = toMilliSecondsOrNull(data.end_detection_gap);
    }
    if ("start_state" in data) {
      this.startState = data.start_state;
    }
    if ("end_state" in data) {
      this.endState = data.end_state || null;
    }

    if ("turnaround_id" in data) {
      this.turnaroundId = data.turnaround_id;
    }

    if (this.endState === "RETRACTED") {
      this.end = null;
    }

    if (di.resolve("configOverrides").isDebug) {
      this.rawData = data;
    }
  }

  clone() {
    const clone = new (this.constructor as any)(
      this.rawData,
      this.origin,
      this._airportConfigStore
    );

    clone.id = this.id;
    clone.confidence = this.confidence;
    clone.type = this.type;
    clone.label = this.label;
    clone.start = this.start;
    clone.startType = this.startType;
    clone.startLabel = this.startLabel;
    clone.end = this.end;
    clone.endType = this.endType;
    clone.endLabel = this.endLabel;
    clone.endState = this.endState;
    clone.startState = this.startState;
    clone.startConfidence = this.startConfidence;
    clone.startDetectionGap = this.startDetectionGap;
    clone.endConfidence = this.endConfidence;
    clone.endDetectionGap = this.endDetectionGap;
    clone.originalId = this.originalId;

    return clone as DetectionPartial;
  }
}

export class DetectionDetailed extends DetectionPartial {
  bboxRanges: Record<string, BBox[]> = {};
  override rawData: DetectionDetailDTO = {} as DetectionDetailDTO;

  constructor(
    dto: DetectionDetailDTO | null,
    origin: "api" | "pts" | "middleware" | "ui",
    airportConfigStore: AirportConfigStore,
    readonly isSafety: boolean = false
  ) {
    super(dto, origin, airportConfigStore);

    if (dto) {
      this.update(dto);
    }

    makeObservable(this, {
      bboxRanges: observable,
    });
  }

  override update(data: DetectionDetailDTO) {
    super.update(data);

    this.bboxRanges = {};
    this.bboxRanges = parseBBoxes(data.bbox_ranges, this.start, this.end);
  }

  override clone(): DetectionPartial {
    throw new Error("Method not implemented.");
  }
}
