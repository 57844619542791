import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { IS_DEV } from "@constants";

import { App } from "@ui/App";
import { registerDependencies } from "@di/diProduction";
import { OIDCAppGate } from "@ui/App/OIDCAppGate";
import "./styles/index.scss";
import "./models/global";

window._COMMIT_HASH = process.env.CI_COMMIT_SHA || "none";
window._FE_CONFIG_VERSION = process.env.FE_CONFIG_VERSION || "none";

registerDependencies();

// TODO: Uncomment (`createRoot` API has breaking changes)
// https://reactjs.org/link/switch-to-createroot

// const root = createRoot(document.getElementById("root") as HTMLElement);
//
// root.render(
//   <OIDCAppGate>
//     <App />
//   </OIDCAppGate>
// );

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <StrictMode>
    <OIDCAppGate>
      <App />
    </OIDCAppGate>
  </StrictMode>,
  document.getElementById("root")
);

if (IS_DEV) {
  // @ts-ignore
  if (module["hot"]) {
    // @ts-ignore
    module["hot"].accept();
  }
}
