import { IntlShape } from "react-intl";

import { Stand } from "@models/stand";
import { getConfig } from "@di";
import { UNKNOWN_GROUP, UNKNOWN_GROUP_MESSAGE } from "@constants";
import { capitalize } from "@services/utils";

export const getAliasedStandLabel = ({ alias, label }: Stand) => {
  const str = alias ? `${label}/${alias}` : label;

  return str.toUpperCase();
};

export const generateLabelById = (id: string) => {
  return id.split("-").slice(1).join("-");
};

export const getStandLabelByStandIdFromConfig = (
  standId: string
): string | null => {
  const { standIdToStandLabelMap } = getConfig();
  return standIdToStandLabelMap[standId] || null;
};

export const getGroupLabel = (label: null | string, intl: IntlShape) => {
  if (!label || label === UNKNOWN_GROUP) {
    return intl.formatMessage(UNKNOWN_GROUP_MESSAGE);
  }

  return label.split(" ").map(capitalize).join(" ");
};

export const getTerminalTitle = (terminal: string, intl: IntlShape) => {
  if (!terminal || terminal === UNKNOWN_GROUP) {
    return intl.formatMessage(UNKNOWN_GROUP_MESSAGE);
  }

  return terminal;
};
export const getStandsByTerminal = (
  stands: Stand[],
  terminal: string | undefined
) => {
  if (!terminal) {
    return stands;
  }

  return stands.filter((stand) => stand.terminal === terminal);
};
export const filterItemsByStandPatterns = <
  T extends
    | {
        standId: string;
      }
    | {
        id: string;
      },
>(
  items: T[],
  getter: (v: T) => string,
  standPatterns: string[]
) => {
  // Do not filter at all if no stand patterns
  if (!standPatterns.length) {
    return items;
  }

  return items.filter((item) =>
    standPatterns.some((pattern) => {
      const value = getter(item);
      return value.includes(pattern);
    })
  );
};
