import { defineMessage } from "react-intl";

export const EMPTY_APRON_LABEL = defineMessage({
  defaultMessage: "No active turnaround",
  description: "Strings constants: EMPTY_APRON_LABEL",
});

export const NOT_APPLICABLE = defineMessage({
  defaultMessage: "N/A",
  description: "[ignore] Strings constants: N/A",
});

export const DOT_DELIMITER = defineMessage({
  defaultMessage: "•",
  description: "[ignore] Delimiter",
});
